import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const Expired = () => {
  return (
    <>
      <Row className="my-5">
        <Col className="d-flex justify-content-center">
          <h1 className="display-heading-3">
            This opportunity is no longer available.
          </h1>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <p>
            If you would like to make a donation, please choose the amount you
            would like to donate from the options below:
          </p>
        </Col>
      </Row>
      <Row className="my-5 justify-content-center">
        <Col lg={2} sm={4} className="text-center mb-4 mb-sm-0">
          <Link to="/NTA6MCU=" className="btn btn-primary">
            Donate $50
          </Link>
        </Col>
        <Col lg={2} sm={4} className="text-center">
          <Link to="/MTAwOjAl" className="btn btn-primary">
            Donate $100
          </Link>
        </Col>
        <Col lg={2} sm={4} className="text-center my-4 my-sm-0">
          <Link to="/MjUwOjAl" className="btn btn-primary">
            Donate $250
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default Expired;
