class Base64Utils {
  static decode(payload) {
    let decoded = decodeURIComponent(
      escape(Buffer.from(payload, "base64").toString())
    );
    let split = decoded.split("|");
    if (split.length === 1) split = decoded.split(":");
    if (split.length === 1) throw new Error("Could not decode payload");
    return split;
  }

  static encode(payload) {
    return Buffer.from(unescape(encodeURIComponent(payload))).toString(
      "base64"
    );
  }
}

export default Base64Utils;
