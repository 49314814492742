import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../Loading.scss";

const Loading = () => {
  return (
    <Row className="mt-5">
      <Col className="d-flex justify-content-center align-items-center vh-50 loading-container">
        <ul className="loading">
          <li />
          <li />
          <li />
        </ul>
      </Col>
    </Row>
  );
};

export default Loading;
