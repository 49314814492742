import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Error = () => {
  return (
    <>
      <Row className="my-5">
        <Col className="d-flex justify-content-center">
          <h1 className="display-heading-3">An error has occurred</h1>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <p>Our support team has been notified.</p>
        </Col>
      </Row>
    </>
  );
};

export default Error;
