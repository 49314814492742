import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Base64Utils from "../utils/Base64Utils";
import accounting from "accounting";
import { useParams } from "react-router-dom";

const FastScratchThankYou = () => {
  let { payload } = useParams();
  let [unformattedAmount, percentage] = Base64Utils.decode(payload);
  let amountPrecision = unformattedAmount % 1 ? 2 : 0;
  let amount = accounting.formatMoney(unformattedAmount, "$", amountPrecision);

  let unformattedTotal =
    parseInt(percentage) * 0.01 * parseInt(unformattedAmount) +
    parseInt(unformattedAmount);
  let totalPrecision = unformattedTotal % 1 ? 2 : 0;
  let total = accounting.formatMoney(unformattedTotal, "$", totalPrecision);

  return (
    <>
      <Row className="my-5">
        <Col className="d-flex justify-content-center">
          <h1 className="display-heading-3">
            {percentage === "0%"
              ? "Thank you for playing!"
              : "Congratulations!"}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <p className="lead">
            {percentage === "0%"
              ? `Your donation for ${amount} has been received.`
              : `Thank you for your donation of ${amount}. We will match ${percentage}% for a total donation amount of ${total}.`}
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center text-muted">
          <p>You will receive a confirmation email for your tax records.</p>
        </Col>
      </Row>
    </>
  );
};

export default FastScratchThankYou;
