import PaymentDetailsContext from "./PaymentDetailsContext";
import { useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
const PaymentDetailsProvider = ({ children }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const context = {
    clientSecret,
    setClientSecret,
    stripe,
    elements,
  };
  return (
    <PaymentDetailsContext.Provider value={context}>
      {children}
    </PaymentDetailsContext.Provider>
  );
};

export default PaymentDetailsProvider;
