import React, { useContext, useEffect, useState } from "react";
import "../DonateForm.scss";
import { useFormik } from "formik";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Navigate, useParams } from "react-router-dom";
import accounting from "accounting";
import Base64Utils from "../utils/Base64Utils";
import Loading from "./Loading";
import WantToPlay from "./WantToPlay";
import DonationUtils from "../utils/DonationUtils";
import { CardNumberElement } from "@stripe/react-stripe-js";
import PaymentDetailsContext from "./PaymentDetailsContext";
import PaymentDetailsCard from "./PaymentDetailsCard";
import DonationDetailsCard from "./DonationDetailsCard";
import SubmitButton from "./SubmitButton";
import { Loader } from "@googlemaps/js-api-loader";

const DonateForm = () => {
  const [render, setRender] = useState("loading");
  const [googleMaps, setGoogleMaps] = useState(null);
  const [wantToPlayParams, setWantToPlayParams] = useState({});
  const [donationDetails, setDonationDetails] = useState(
    DonationUtils.defaultDonationDetails
  );
  const { clientSecret, setClientSecret, stripe, elements } = useContext(
    PaymentDetailsContext
  );
  let { payload } = useParams();

  const onSubmit = async (values, actions) => {
    try {
      let amount, transactionId;
      if (values.paymentDetails.paymentType === "gift-code") {
        let result = await axios.post(
          `${
            process.env.REACT_APP_API_HOSTNAME_PREFIX || ""
          }/api/redeemGiftCode`,
          {
            fullName: values.paymentDetails.fullName,
            email: values.paymentDetails.email,
            giftCodeName: values.paymentDetails.giftCode,
            amount: values.donationDetails.amount,
            match: values.donationDetails.match,
            charityName: values.donationDetails.charityName,
            charityAddress: values.donationDetails.charityAddress,
            charityWebsite: values.donationDetails.charityWebsite,
            remainAnonymous: values.donationDetails.remainAnonymous,
          }
        );
        if (result.data?.error) {
          setStatus({});
          actions.setFieldError("paymentDetails.giftCode", result.data.error);
          actions.setSubmitting(false);
          return;
        }
        transactionId = result.data.transactionId;
        amount = parseFloat(values.donationDetails.amount) * 100;
      } else {
        let { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: {
                name: values.paymentDetails.fullName
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, ""), //remove diacritics,
                email: values.paymentDetails.email,
                address: {
                  postal_code: values.paymentDetails.zip,
                },
              },
              metadata: {
                charityName: values.donationDetails.charityName,
                charityAddress: values.donationDetails.charityAddress,
                charityWebsite: values.donationDetails.charityWebsite,
                amount: values.donationDetails.amount,
                match: values.donationDetails.match,
                remainAnonymous: values.donationDetails.remainAnonymous,
              },
            },
          }
        );
        if (error) {
          setStatus({ ...status, error: error.message });
          actions.setSubmitting(false);
          return;
        }
        if (paymentIntent && paymentIntent.status !== "succeeded") {
          setStatus({
            ...status,
            error: "Payment did not succeed. Please try again.",
          });
          actions.setSubmitting(false);
          return;
        }
        transactionId = paymentIntent.id;
        amount = paymentIntent.amount;
        actions.setSubmitting(false);
      }

      if (values.donationDetails.match !== "FastScratch") {
        window.location.replace(
          `${process.env.REACT_APP_VAFBTH_BASE_URL}/thank-you`
        );
        return;
      }

      // if we got here, we are playing FastScratch
      setRender("wantToPlay");
      setWantToPlayParams((prevState) => ({
        ...prevState,
        amount: amount,
        transactionId: Base64Utils.encode(transactionId),
      }));
    } catch (e) {
      setStatus({
        ...status,
        error:
          "We encountered an error when processing your transaction. Please try again.",
      });
      actions.setSubmitting(false);
    }
  };

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    setSubmitting,
    setFieldError,
    setFieldValue,
    status,
    setStatus,
  } = useFormik({
    validationSchema: DonationUtils.schema,
    enableReinitialize: true,
    initialStatus: { error: null },
    initialValues: {
      donationDetails: { ...donationDetails },
      paymentDetails: { ...DonationUtils.defaultPaymentDetails },
    },
    onSubmit: onSubmit,
  });

  useEffect(() => {
    const loadTokenAndSetupState = async () => {
      if (payload && render === "loading") {
        try {
          const googleLoader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
            version: "weekly",
            libraries: ["places"],
          });
          const google = await googleLoader.load();
          setGoogleMaps(google.maps);
          let [payloadAmount, payloadMatch, payloadCharityWebsite] =
            Base64Utils.decode(payload);
          let response = await axios.post(
            `${process.env.REACT_APP_API_HOSTNAME_PREFIX || ""}/api/setup`,
            {
              amount: payloadAmount,
              match: payloadMatch,
              charityWebsite: payloadCharityWebsite,
            }
          );
          let {
            charityName,
            charityAddress,
            charityWebsite,
            match,
            remainAnonymous,
            amount,
            clientSecret,
            fastScratchId,
          } = response.data;

          setClientSecret(clientSecret);
          setWantToPlayParams((prevState) => ({
            ...prevState,
            fastScratchId: fastScratchId,
          }));
          setDonationDetails((prevState) => ({
            ...prevState,
            match: match,
            remainAnonymous: remainAnonymous,
            amount: amount,
          }));

          if (charityName && charityAddress && charityWebsite) {
            setDonationDetails((prevState) => ({
              ...prevState,
              charityName: charityName,
              charityAddress: charityAddress,
              charityWebsite: charityWebsite,
              readOnly: true,
            }));
          } else {
            setDonationDetails((prevState) => ({
              ...prevState,
              charityAddressHelperText:
                "Enter the charity name for suggestions.",
            }));
          }
          if (match.includes("%") && match !== "0%") {
            let total = parseInt(match) * 0.01 * amount + parseInt(amount);
            let precision = total % 1 ? 2 : 0;
            let formattedTotal = accounting.formatMoney(total, "$", precision);
            setDonationDetails((prevState) => ({
              ...prevState,
              matchText: `Your card will only be charged $${amount}. VAF By The Hand will match ${match} of the donation, making the total amount ${formattedTotal}. Thank you for your support!`,
            }));
          }
          if (match.includes("$")) {
            setDonationDetails((prevState) => ({
              ...prevState,
              matchText: `Receive a ${match} gift card after your donation`,
            }));
          }
          setRender("form");
        } catch (e) {
          if (e.response && e.response.status === 404) {
            setRender("expired");
          } else {
            setRender("error");
          }
        }
      }
    };
    loadTokenAndSetupState();
  }, [payload, render]);

  if (render === "loading") return <Loading />;
  if (render === "error") return <Navigate to="/error" />;
  if (render === "expired") return <Navigate to="/expired" />;
  if (render === "wantToPlay")
    return (
      <WantToPlay
        amount={wantToPlayParams.amount}
        transactionId={wantToPlayParams.transactionId}
        fastScratchId={wantToPlayParams.fastScratchId}
      />
    );
  return (
    <Row className="my-5 justify-content-center">
      <Col lg={5}>
        <Form id="donation-form" noValidate={true} onSubmit={handleSubmit}>
          <Row className="row mb-5">
            <Col>
              <DonationDetailsCard
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                googleMaps={googleMaps}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <PaymentDetailsCard
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                status={status}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SubmitButton
                values={values}
                dirty={dirty}
                isValid={isValid}
                isSubmitting={isSubmitting}
                stripe={stripe}
                elements={elements}
                setSubmitting={setSubmitting}
                setFieldError={setFieldError}
              />
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default DonateForm;
