import React from "react";
import Button from "react-bootstrap/Button";
import { FaSpinner } from "react-icons/fa";

const SubmitButton = ({
  values,
  dirty,
  isValid,
  isSubmitting,
  stripe,
  elements,
}) => {
  const disabled =
    values.paymentDetails.paymentType === "credit-card"
      ? !dirty || !isValid || isSubmitting || !stripe || !elements
      : !dirty || !isValid || isSubmitting;
  return (
    <div className="d-grid">
      <Button variant="primary" disabled={disabled} type="submit">
        {isSubmitting ? <FaSpinner className="icon-spin" /> : "Submit"}
      </Button>
    </div>
  );
};

export default SubmitButton;
