import React, { Fragment } from "react";
import Card from "react-bootstrap/Card";
import creditCardLogos from "../credit-card-logos.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { ButtonGroup, InputGroup } from "react-bootstrap";

const PaymentDetailsCard = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  status,
}) => {
  return (
    <Card className="card">
      <Card.Header className="d-inline-flex justify-content-between align-items-center">
        <h5 className="m-0">Payment Details</h5>
        <img
          className="img-responsive"
          src={creditCardLogos}
          alt="Accepted Cards"
        />
      </Card.Header>
      <Card.Body className="card-body">
        <Row className="mb-3">
          <Col md={6}>
            <ButtonGroup>
              <Form.Check.Input
                type="radio"
                id="payment-type-credit-card"
                name="paymentDetails.paymentType"
                value="credit-card"
                onChange={handleChange}
                onBlur={handleBlur}
                bsPrefix="btn-check"
                checked={values.paymentDetails.paymentType === "credit-card"}
              />
              <Form.Check.Label
                bsPrefix="btn btn-outline-primary"
                tabIndex={0}
                htmlFor="payment-type-credit-card"
              >
                Credit Card
              </Form.Check.Label>
              <Form.Check.Input
                type="radio"
                id="payment-type-gift-code"
                name="paymentDetails.paymentType"
                value="gift-code"
                onChange={handleChange}
                onBlur={handleBlur}
                bsPrefix="btn-check"
                checked={values.paymentDetails.paymentType === "gift-code"}
              />
              <Form.Check.Label
                bsPrefix="btn btn-outline-primary"
                tabIndex={0}
                htmlFor="payment-type-gift-code"
              >
                Gift Code
              </Form.Check.Label>
            </ButtonGroup>
          </Col>
        </Row>
        {status.error && (
          <Row>
            <Col>
              <p className="text-danger">{status.error}</p>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                name="paymentDetails.fullName"
                placeholder="Full Name"
                isInvalid={
                  touched.paymentDetails &&
                  touched.paymentDetails.fullName &&
                  !!(errors.paymentDetails && errors.paymentDetails.fullName)
                }
                isValid={
                  touched.paymentDetails &&
                  touched.paymentDetails.fullName &&
                  !(errors.paymentDetails && errors.paymentDetails.fullName)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDetails.fullName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.paymentDetails && errors.paymentDetails.fullName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {values.paymentDetails.paymentType === "credit-card" && (
          <Fragment>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <CardNumberElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#495057",
                          "::placeholder": {
                            color: "rgb(108, 117, 125)",
                          },
                        },
                      },
                    }}
                    onChange={(e) =>
                      setFieldValue(
                        "paymentDetails.cardNumberComplete",
                        e.complete
                      )
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.paymentDetails &&
                      errors.paymentDetails.cardNumberComplete}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <CardExpiryElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#495057",
                          "::placeholder": {
                            color: "rgb(108, 117, 125)",
                          },
                        },
                      },
                    }}
                    className="form-control"
                    onChange={(e) =>
                      setFieldValue("paymentDetails.expiryComplete", e.complete)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.paymentDetails &&
                      errors.paymentDetails.expiryComplete}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <CardCvcElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#495057",
                          "::placeholder": {
                            color: "rgb(108, 117, 125)",
                          },
                        },
                      },
                    }}
                    className="form-control"
                    onChange={(e) =>
                      setFieldValue("paymentDetails.cvcComplete", e.complete)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.paymentDetails && errors.paymentDetails.cvcComplete}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="paymentDetails.zip"
                    placeholder="Zip"
                    isInvalid={
                      touched.paymentDetails &&
                      touched.paymentDetails.zip &&
                      !!(errors.paymentDetails && errors.paymentDetails.zip)
                    }
                    isValid={
                      touched.paymentDetails &&
                      touched.paymentDetails.zip &&
                      !(errors.paymentDetails && errors.paymentDetails.zip)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.paymentDetails.zip}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.paymentDetails && errors.paymentDetails.zip}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Fragment>
        )}
        {values.paymentDetails.paymentType === "gift-code" && (
          <Fragment>
            <Row>
              <Col>
                <InputGroup className="mb-3" hasValidation={true}>
                  <Form.Control
                    type="text"
                    name="paymentDetails.giftCode"
                    className="gift-code"
                    placeholder="Gift Code"
                    isInvalid={
                      touched.paymentDetails?.giftCode &&
                      !!errors.paymentDetails?.giftCode
                    }
                    isValid={
                      touched.paymentDetails?.giftCode &&
                      !errors.paymentDetails?.giftCode
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.paymentDetails.giftCode}
                  />
                  {errors.paymentDetails?.giftCode && (
                    <Form.Control.Feedback type="invalid">
                      {errors.paymentDetails?.giftCode}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
              </Col>
            </Row>
          </Fragment>
        )}
        <Row className="mb-1">
          <Col>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                name="paymentDetails.email"
                placeholder="Email"
                isValid={
                  touched.paymentDetails &&
                  touched.paymentDetails.email &&
                  !(errors.paymentDetails && errors.paymentDetails.email)
                }
                isInvalid={
                  touched.paymentDetails &&
                  touched.paymentDetails.email &&
                  !!(errors.paymentDetails && errors.paymentDetails.email)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDetails.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.paymentDetails && errors.paymentDetails.email}
              </Form.Control.Feedback>
            </Form.Group>
            <small className="form-text text-muted">
              A receipt will be sent to this email address. This email address
              will not be shared with any third party.
            </small>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PaymentDetailsCard;
