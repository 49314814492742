import React, { lazy, Suspense, Fragment } from "react";
import logo from "./logo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DonateForm from "./components/DonateForm";
import Error from "./components/Error";
import Expired from "./components/Expired";
import FastScratchThankYou from "./components/FastScratchThankYou";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDetailsProvider from "./components/PaymentDetailsProvider";
import Loading from "./components/Loading";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY);

function App() {
  const DummyVafWebsite = lazy(() => import("./components/DummyVafWebsite"));
  const EmailEditorPage = lazy(() => import("./components/EmailEditorPage"));

  return (
    <Elements stripe={stripePromise}>
      <Suspense fallback={<Loading />}>
        <Router>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={5}>
                <a href={process.env.REACT_APP_VAFBTH_BASE_URL}>
                  <img
                    className="mx-auto d-block"
                    src={logo}
                    alt="VAF By the Hand Foundation"
                  />
                </a>
              </Col>
            </Row>
            <Routes>
              {process.env.NODE_ENV === "development" && (
                <Fragment>
                  <Route path="/" exact element={<DummyVafWebsite />} />
                  <Route
                    path="/thank-you"
                    exact
                    element={<DummyVafWebsite />}
                  />
                  <Route
                    path="/refund-policy.html"
                    exact
                    element={<DummyVafWebsite />}
                  />
                  <Route
                    path="/terms-and-privacy-policy.html"
                    exact
                    element={<DummyVafWebsite />}
                  />
                </Fragment>
              )}
              {process.env.NODE_ENV === "development" && (
                <Route
                  path="/email-editor"
                  exact
                  element={<EmailEditorPage />}
                />
              )}
              <Route path="/error" element={<Error />} />
              <Route path="/expired" element={<Expired />} />
              <Route
                path="/fastscratch/:payload/thank-you"
                element={<FastScratchThankYou />}
              />
              <Route
                path="/:payload"
                element={
                  <PaymentDetailsProvider>
                    <DonateForm />
                  </PaymentDetailsProvider>
                }
              />
            </Routes>
            <Row className="mt-5 mb-3 justify-content-center">
              <Col lg={3} sm={4} className="text-center">
                <a
                  className="text-muted"
                  href={`${process.env.REACT_APP_VAFBTH_BASE_URL}/refund-policy.html`}
                >
                  Refund Policy
                </a>
              </Col>
            </Row>
            <Row className="mb-5 justify-content-center">
              <Col lg={3} sm={4} className="text-center">
                <a
                  className="text-muted"
                  href={`${process.env.REACT_APP_VAFBTH_BASE_URL}/terms-and-privacy-policy.html`}
                >
                  Terms and Privacy Policy
                </a>
              </Col>
            </Row>
          </Container>
        </Router>
      </Suspense>
    </Elements>
  );
}

export default App;
