import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import accounting from "accounting";
import querystring from "querystring";

const WantToPlay = ({ amount, transactionId, fastScratchId }) => {
  let precision = amount % 1 ? 2 : 0;
  let donation = accounting.formatMoney(amount / 100, "$", precision);
  let queryParams = querystring.stringify({
    did: transactionId,
    cid: fastScratchId,
  });
  let url = `${process.env.REACT_APP_FASTSCRATCH_BASE_URL}/fastscratch/welcome?${queryParams}`;

  return (
    <>
      <Row className="mt-5">
        <Col className="d-flex justify-content-center">
          <h1 className="display-heading-3">Want to play?</h1>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="d-flex justify-content-center">
          <p className="lead">Thank you for your donation of {donation}.</p>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="d-flex justify-content-center">
          <p className="lead">
            Would you like to play the FastScratch game to win a matching
            donation?
          </p>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="d-flex justify-content-center">
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              window.location.replace(url);
            }}
          >
            Click here to play
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default WantToPlay;
