import { boolean, object, string } from "yup";

class DonationUtils {
  static defaultDonationDetails = {
    charityName: "",
    charityAddress: "",
    charityAddressSearch: "",
    charityAddressHelperText: "",
    charityWebsite: "",
    match: "",
    matchText: "",
    readOnly: false,
    remainAnonymous: false,
    amount: "",
  };

  static defaultPaymentDetails = {
    fullName: "",
    cardNumberComplete: false,
    cvcComplete: false,
    expiryComplete: false,
    email: "",
    zip: "",
    giftCode: "",
    paymentType: "credit-card",
  };

  static schema = object({
    donationDetails: object({
      charityName: string().required("Charity name can't be blank"),
      charityAddress: string().required("Charity address can't be blank"),
      charityAddressSearch: string(),
      charityAddressHelperText: string(),
      charityWebsite: string().matches(
        // eslint-disable-next-line
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        {
          message: "Charity website must be a valid url",
          excludeEmptyString: true,
        }
      ),
      remainAnonymous: boolean(),
      match: string(),
      matchText: string(),
      readOnly: boolean(),
      amount: string(),
    }),
    paymentDetails: object({
      fullName: string().required("Full name can't be blank"),
      cardNumberComplete: boolean().when("paymentType", {
        is: "credit-card",
        then: boolean().equals([true], "Card number is invalid"),
        otherwise: boolean(),
      }),
      expiryComplete: boolean().when("paymentType", {
        is: "credit-card",
        then: boolean().equals([true], "Expiration date is invalid"),
        otherwise: boolean(),
      }),
      cvcComplete: boolean().when("paymentType", {
        is: "credit-card",
        then: boolean().equals([true], "Security code is invalid"),
        otherwise: boolean(),
      }),
      email: string()
        .required("Email can't be blank")
        .email("Email must be a valid email address"),
      paymentType: string().required("Payment type can't be blank"),
      giftCode: string().when("paymentType", {
        is: "gift-code",
        then: string().required("Gift code can't be blank"),
        otherwise: string(),
      }),
      zip: string().when("paymentType", {
        is: "credit-card",
        then: string()
          .required("Zip can't be blank")
          .min(5, "Zip is invalid")
          .max(11, "Zip is invalid"),
        otherwise: string(),
      }),
    }),
  });
}

export default DonationUtils;
