import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import GooglePlacesSuggest from "react-google-places-suggest";

const DonationDetailsCard = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  googleMaps,
}) => {
  return (
    <Card>
      <Card.Header className="align-items-center">
        <h5 className="m-0">Your Donation Details</h5>
      </Card.Header>
      <Card.Body>
        <Row className="mb-1">
          <Col>
            <span>Your Donation Amount:</span>
            <span className="ml-1">${values.donationDetails.amount}</span>
          </Col>
        </Row>
        {values.donationDetails.matchText.length > 0 && (
          <Row className="row mb-3">
            <Col>
              <i>
                <small>{values.donationDetails.matchText}</small>
              </i>
            </Col>
          </Row>
        )}

        <Row className="row mb-4">
          <Col>
            <Form.Check
              type="checkbox"
              label="I would like to remain anonymous"
              name="donationDetails.remainAnonymous"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.donationDetails.remainAnonymous}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Charity Name"
                name="donationDetails.charityName"
                readOnly={values.donationDetails.readOnly}
                isInvalid={
                  touched.donationDetails &&
                  touched.donationDetails.charityName &&
                  !!(
                    errors.donationDetails && errors.donationDetails.charityName
                  )
                }
                isValid={
                  touched.donationDetails &&
                  touched.donationDetails.charityName &&
                  !(
                    errors.donationDetails && errors.donationDetails.charityName
                  )
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.donationDetails.charityName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.donationDetails && errors.donationDetails.charityName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              {googleMaps && (
                <GooglePlacesSuggest
                  googleMaps={googleMaps}
                  autocompletionRequest={{
                    input: values.donationDetails.charityAddressSearch,
                  }}
                  onSelectSuggest={(result) => {
                    setFieldValue(
                      "donationDetails.charityAddress",
                      result.formatted_address
                    );
                    setFieldValue("donationDetails.charityAddressSearch", "");
                  }}
                >
                  <Form.Control
                    placeholder="Charity Address"
                    name="donationDetails.charityAddress"
                    onBlur={handleBlur}
                    readOnly={values.donationDetails.readOnly}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue(
                        "donationDetails.charityAddressSearch",
                        e.target.value
                      );
                    }}
                    value={values.donationDetails.charityAddress}
                    isInvalid={
                      touched.donationDetails &&
                      touched.donationDetails.charityAddress &&
                      !!(
                        errors.donationDetails &&
                        errors.donationDetails.charityAddress
                      )
                    }
                    isValid={
                      touched.donationDetails &&
                      touched.donationDetails.charityAddress &&
                      !(
                        errors.donationDetails &&
                        errors.donationDetails.charityAddress
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.donationDetails &&
                      errors.donationDetails.charityAddress}
                  </Form.Control.Feedback>
                </GooglePlacesSuggest>
              )}
              {values.donationDetails.charityAddressHelperText.length > 0 && (
                <Row>
                  <Col>
                    <small className="text-muted">
                      {values.donationDetails.charityAddressHelperText}
                    </small>
                  </Col>
                </Row>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                name="donationDetails.charityWebsite"
                placeholder="Charity Website (optional)"
                readOnly={values.donationDetails.readOnly}
                isInvalid={
                  touched.donationDetails &&
                  touched.donationDetails.charityWebsite &&
                  !!(
                    errors.donationDetails &&
                    errors.donationDetails.charityWebsite
                  )
                }
                isValid={
                  touched.donationDetails &&
                  touched.donationDetails.charityWebsite &&
                  !(
                    errors.donationDetails &&
                    errors.donationDetails.charityWebsite
                  )
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.donationDetails.charityWebsite}
              />
              <Form.Control.Feedback type="invalid">
                {errors.donationDetails &&
                  errors.donationDetails.charityWebsite}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DonationDetailsCard;
